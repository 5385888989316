/*                                                          */
//
/*                */
/*           */
/*                                       */
//
/*                                                                     */
/*                                                                     */

import { eventQBusEmit, getLogger } from "./assetsHelpers";

const log = getLogger("ft-nav.star-track.wrappers");

/**
 *
 *
 *
 *
 *
 *
 */
export function addToEvent(dataContainer, eventMergeId) {
    const topic = 'tracking.bct.addToEvent';
    log.debug(topic, dataContainer, eventMergeId);
    eventQBusEmit(topic, dataContainer, eventMergeId);
}

/**
 *
 *
 *
 *
 *
 *
 */
export function addActionToEvent(action, eventMergeId) {
    const topic = 'tracking.bct.addActionToEvent';
    log.debug(topic, action, eventMergeId);
    eventQBusEmit(topic, action, eventMergeId);
}

/**
 *
 *
 *
 *
 *
 *
 */
export function submitAction(dataContainer, action) {
    const topic = 'tracking.bct.submitAction';
    log.debug(topic, dataContainer, action);
    eventQBusEmit(topic, dataContainer, action);
}

/**
 *
 *
 *
 *
 *
 *
 */
export function submitMoveAction(dataContainer, action) {
    const topic = 'tracking.bct.submitMoveAction';
    log.debug(topic, dataContainer, action);
    eventQBusEmit(topic, dataContainer, action);
}

/**
 *
 *
 *
 *
 *
 *
 */
export function submitEvent(dataContainer) {
    const topic = 'tracking.bct.submitEvent';
    log.debug(topic, dataContainer);
    eventQBusEmit(topic, dataContainer);
}

/**
 *
 *
 *
 *
 *
 */
export function trackOnNextPageImpression(dataContainer) {
    const topic = 'tracking.bct.trackOnNextPageImpression';
    log.debug(topic, dataContainer);
    eventQBusEmit(topic, dataContainer);
}

/**
 *
 *
 *
 *
 *
 */
export function addFeaturesToPageImpression(features) {
    const topic = 'tracking.bct.addFeaturesToPageImpression';
    log.debug(topic, features);
    eventQBusEmit(topic, features);
}

/**
 *
 *
 *
 *
 *
 */
export function submitMiniActions(miniActions) {
    const topic = 'tracking.bct.submitMiniActions';
    log.debug(topic, miniActions);
    eventQBusEmit(topic, miniActions);
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
