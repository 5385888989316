import {
    addActionToEvent,
    addToEvent,
    addFeaturesToPageImpression,
    submitAction,
    submitEvent,
    submitMoveAction,
    trackOnNextPageImpression,
    submitMiniActions
} from "./wrappers";

function isUppercase(str) {
    return str !== str.toLowerCase();
}

function toKebabCase(str) {
    return Array.from(str).flatMap((c, i) => {
        if (isUppercase(c)) {
            if (i !== 0) {
                return ['-', c.toLowerCase()];
            } else {
                return c.toLowerCase();
            }
        }  else {
            return c;
        }
    }).join('');
}

export const extractTrackingData = elem => {
    const dataset = elem.dataset;
    if (!dataset) return undefined;

    return Object.keys(dataset)
        .filter((elem) => elem.startsWith("navTracking"))
        .reduce((acc, key) => {
            let data = JSON.parse(dataset[key]);
            let newKey = `user-action/${toKebabCase(key.substring("navTracking".length))}`;
            acc[newKey] = data;
            return acc;
        }, {});
};

/**
 *
 *
 */

/**
 *
 *
 *
 */

/**
 *
 */
export const identity = (commands) => commands;

/**
 *
 *
 *
 *
 *
 */
export const track = (elem, userAction, transformFn=identity) => {
    const trackedElem = elem.closest('[data-nav-track="true"]');
    if (!trackedElem) return;

    let commands = extractTrackingData(trackedElem)[userAction];
    if (commands) {
        let transformedCommands = transformFn(commands);
        transformedCommands.forEach(command => {
            switch (command.method) {
                case "submitEvent":
                    submitEvent(command.dataContainer);
                    break;
                case "addFeaturesToPageImpression":
                    addFeaturesToPageImpression(command.features);
                    break;
                case "trackOnNextPageImpression":
                    trackOnNextPageImpression(command.dataContainer);
                    break;
                case "submitAction":
                    submitAction(command.dataContainer, command.action);
                    break;
                case "submitMoveAction":
                    submitMoveAction(command.dataContainer, command.action);
                    break;
                case "addToEvent":
                    addToEvent(command.dataContainer, command.eventMergeId);
                    break;
                case "addActionToEvent":
                    addActionToEvent(command.action, command.eventMergeId);
                    break;
                case "submitMiniActions":
                    submitMiniActions(command.miniActions);
                    break;
            }
        });
    }
};
