import {isMobile} from "./helper";
import {track} from "@otto-ec/nav_star-track/src/js/star-track";
import "@otto-ec/nav_patternsson/src/js/ReferBack";

function addTrackingToBackButton () {
    document.querySelectorAll('.nav_grimm-breadcrumb-headline nav-refer-back')
        .forEach(b =>
            b.addEventListener('click', e =>
                track(e.target, 'user-action/click-back-button')));
}

function init() {
    addTrackingToBackButton();
    if (isMobile()) {
        const clickableBreadcrumbTitle = document.getElementsByClassName('nav_grimm-breadcrumb-headline__title--clickable')[0];

        if (clickableBreadcrumbTitle) {
            clickableBreadcrumbTitle.addEventListener('click', e => {
                o_global.eventQBus.emit('squirrel.search.show', {"breadcrumb": true});
                track(e.target, 'user-action/click-breadcrumb-headline');
            });
        }
    }
}

window.o_global.eventQBus.on('ftnav.breadcrumb.register', init);
window.o_global.eventQBus.on('ftfind.breadcrumb.loaded', init);
window.o_global.eventLoader.onReady(5, init);
