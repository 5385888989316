import {breakpoint} from "@otto-ec/global-resources/breakpoint";
import {Storage} from "@otto-ec/global-resources/storage";
import {defineCustomElement} from "./utils";

export class ReferBack extends HTMLElement {

    constructor () {
        super();
    }

    connectedCallback () {
        this.storage = new Storage(window.sessionStorage);
        this.style.display = "none";
        if (this.displayp()) {
            this.addEventListener('click', this.back);
            this.show();
        }
    }

    disconnectedCallback () {
        this.removeEventListener('click', this.back);
    }

    back (_) {
        history.back();
    }

    displayp () {
        if (!(breakpoint.isSmall())) {
            return false;
        }

        /*                                                                   */
        /*      */
        if (!(document.referrer.includes(window.location.hostname)) ||
            (history.length <= 1)) {
            /*                                     */
            this.storage.setItem("nav-refer-back-entry-point", location.href);
            return false;
        }

        if (this.storage.getItem("nav-refer-back-entry-point") === location.href) {
            /*                                                                     */
            return false;
        }

        return true;
    }

    show () {
        this.style.display = "inline-block";
    }
}

defineCustomElement("nav-refer-back", ReferBack);
