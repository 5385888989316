/**
 *
 */
export function isBreakpointS() {
    const currentBreakpoint = o_global.breakpoint.getCurrentBreakpoint();
    return currentBreakpoint === "s";
}

export function isMobile() {
    const currentBreakpoint = o_global.breakpoint.getCurrentBreakpoint();
    return currentBreakpoint === "s" || currentBreakpoint === "m";
}

export function isDebugMode() {
    if (window.o_global &&
        window.o_global.debug &&
        window.o_global.debug.status &&
        typeof window.o_global.debug.status === 'function') {
        return window.o_global.debug.status().activated;
    } else {
        return false;
    }
}
